import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { HiOutlineMail } from "react-icons/hi";
import { AiOutlinePhone } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { MdOutlineLocationOn } from "react-icons/md";
import { MdOutlineMessage } from "react-icons/md";
import { FaTasks } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { BiLogOut } from "react-icons/bi";
import { RiCopperCoinLine } from "react-icons/ri";
import UserNavBar from "../UserNavBar/UserNavBar";
import "./UserProfile.css";
import user from "../../images/user.png";
export default function UserProfile({ userInfo }) {
    if (!userInfo) {
        // Handle the loading state or display a placeholder content
        return <div>Loading...</div>;
      }
    return (
            <Container>
                        <Card className="userprofile__card">
                            <Card.Img variant="top" src={user} className="userprofile__image mx-auto" style={{ width: '35%', height: 'auto' }}/>
                            <Card.Body>
                                <Card.Title className="userprofile__title">
                                {userInfo.user.name}
                                </Card.Title>
                                <Card.Text className="userprofile__text d-flex align-items-center">
                                    <div className="userprofile__text__icon me-2">
                                        <HiOutlineMail />
                                    </div>
                                    <div className="userprofile__text__content">

                                        <p className="mb-0 pl-2">
                                        {userInfo.user.email}
                                        </p>
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>
            </Container>
    );
}
