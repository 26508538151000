import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form, Button } from "react-bootstrap";
import { TraceSpinner } from "react-spinners-kit";
import "./EditTrack.css";

export default function EditTrack() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [imageData, setImageData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("adminToken")) {
      navigate("/tutor_login");
    } else {
      fetch(`${process.env.REACT_APP_BASE_URL}/api/admin/track/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((tdata) => {
          setName(tdata.track.name);
          setDescription(tdata.track.description);
          setImageData(tdata.track.image);
        });
    }
  }, [id, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setError(false);
    setErrorMessage("");

    const formData = new FormData();
    formData.append("image", imageData);
    formData.append("name", name);
    formData.append("description", description);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/track/${id}`,
        {
          method: "PUT",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.error) {
        setError(true);
        setErrorMessage(data.error);
        setTimeout(() => {
          setError(false);
        }, 2000);
      } else {
        setSuccessMessage("Updated successfully.");
        setTimeout(() => {
          navigate("/tutor_home");
        }, 2000);
      }
    } catch (error) {
      setError(true);
      setErrorMessage("An error occurred. Please try again later.");
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="add-track-div">
        <Card className="add-track-card">
          <Card.Title>Update Track</Card.Title>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Track Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Track Name"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Track Description</Form.Label>
                <Form.Control
                  as="textarea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows="3"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="image">Track Image</Form.Label>
                <Form.Control
                  type="File"
                  accept="image/*"
                  value={image}
                  onChange={(e) => {
                    setImage(e.target.value);
                    setImageData(e.target.files[0]);
                  }}
                  name="image"
                  placeholder="Upload Track Image"
                />
              </Form.Group>
              <img className="edit-image" src={imageData} alt="track"/>
              <div className="add-track-btn">
                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? (
                    <TraceSpinner size={25} frontColor="cyan" loading={loading} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </Form>
            <div className="errorMessage">{error ? errorMessage : ""}</div>
            <div className="successMessage">{successMessage}</div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}