import React, { useState } from "react";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import logo from "../../../images/logo.png";
import "./Navigation.css";
import Dropdown from 'react-bootstrap/Dropdown';

export default function Navigation() {
  const navigate = useNavigate();
  const [loginDropdownOpen, setLoginDropdownOpen] = useState(false);
  const [signupDropdownOpen, setSignupDropdownOpen] = useState(false);

  const handleSignupClick = (e) => {
    e.preventDefault();
    setSignupDropdownOpen(!signupDropdownOpen);
    setLoginDropdownOpen(false);
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    setLoginDropdownOpen(!loginDropdownOpen);
    setSignupDropdownOpen(false);
  };

  const handleLoginOptionClick = (option) => {
    // Redirect to the appropriate login route based on the selected option
    if (option === "teacher") {
      navigate("/tutor_login");
    } else if (option === "student") {
      navigate("/login");
    }
    setLoginDropdownOpen(false);
  };

  const handleSignupOptionClick = (option) => {
    // Redirect to the appropriate signup route based on the selected option
    if (option === "teacher") {
      navigate("/tutor_register");
    } else if (option === "student") {
      navigate("/register");
    }
    setSignupDropdownOpen(false);
  };

  return (
    <div className="navbar">
      <div className="navbar-head">
        <a href="/" className="navbar-logo">
          <img className="nav-header-logo" src={logo} alt="logo" />
        </a>
        <div className="navbar-links">
          <span>
            <Link activeClass="active" to="home" spy={true} smooth={true}>
              Home
            </Link>
          </span>
          <span>
            <Link to="about" spy={true} smooth={true}>
              About
            </Link>
          </span>
          <span>
            <Link to="footer" spy={true} smooth={true}>
              Contact Us
            </Link>
          </span>
        </div>
      </div>
      <div className="justify-content-left buttons d-flex">
        <Dropdown show={loginDropdownOpen}>
          <Dropdown.Toggle variant="danger" id="login-dropdown" onClick={handleLoginClick}>
            Login
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleLoginOptionClick("teacher")}>
              As Teacher
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleLoginOptionClick("student")}>
              As Student
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown show={signupDropdownOpen}>
          <Dropdown.Toggle variant="danger" id="signup-dropdown" onClick={handleSignupClick}>
            Signup
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleSignupOptionClick("teacher")}>
             As Teacher
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSignupOptionClick("student")}>
              As Student
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}