import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Row, Col, ProgressBar } from 'react-bootstrap';
import PreLoader from "../PreLoader/PreLoader";
import UserNavBar from "../UserNavBar/UserNavBar";
import Footer from "../Footer/Footer";
import "./EnrolledCourses.css";

export default function EnrolledCourses() {
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [userCourses, setUserCourses] = useState([]);
  const [userInfo, setUserInfo] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!localStorage.getItem("token")) {
          navigate("/login");
          return;
        }

        const verifyTokenResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/user/verifyToken`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: localStorage.getItem("token"),
          }),
        });

        const verifyTokenData = await verifyTokenResponse.json();

        if (!verifyTokenData.user) {
          localStorage.removeItem("token");
          navigate("/");
          return;
        }

        setUserInfo(verifyTokenData);
        setUserId(verifyTokenData.user._id);

        const userCoursesResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/admin/userCourses`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: verifyTokenData.user._id,
            token: localStorage.getItem("token"),
          }),
        });

        const userCoursesData = await userCoursesResponse.json();
        console.log(userCoursesData);
        setUserCourses(userCoursesData.userCourses);
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      setLoading(false);
    };

    fetchData();
  }, [navigate]);


  return (
    <div>
      {loading ? (
        <PreLoader />
      ) : (
        <div>
          {/* Navbar component */}
          <UserNavBar userInfo={userInfo} />

          <div className="main-enrolled-courses container mb-4">
            <h2 className="track-heading">Your Enrolled Courses</h2>
            {userCourses.length > 0 ? (
              <Row xs={1} md={3} className="g-4">
                {userCourses.map((course) => (
                <Col key={course._id}>
                  <Card className="card-course">
                    <Card.Img variant="top" src={course.course.image} className="card-img" />
                    <Card.Body>
                      <Card.Title>{course.course.name}</Card.Title>
                      <Card.Text>{course.course.description}</Card.Text>
                      <ProgressBar now={((course.progress / course.course.videos.length) * 100)} label={`${((course.progress / course.course.videos.length) * 100).toFixed(2)}%`} />
                      <p>Completed: {course.completed ? "Yes" : "No"}</p>
                      <Button variant="primary" onClick={() => navigate(`/course/${course.course._id}`)}>Go to Course</Button>
                    </Card.Body>
                  </Card>
                </Col>
                ))}
              </Row>
            ) : (
              <p>No enrolled courses found.</p>
            )}
          </div>

          {/* footer */}
          <Footer />
        </div>
      )}
    </div>
  );
}
