import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Container,
  Navbar,
  Nav,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import { MdOutlineMessage } from "react-icons/md";
import { FaTasks } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { BiLogOut } from "react-icons/bi";
import { RiCopperCoinLine } from "react-icons/ri";
import "./UserNavBar.css";
import UserProfile from "../UserProfile/UserProfile";
import { Modal, Button } from "react-bootstrap";
import logo from "../../images/logo.png";
import user from "../../images/user.png";

export default function UserNavBar({ userInfo }) {
  const navigate = useNavigate();

  const [showProfileModal, setShowProfileModal] = useState(false);

  const openProfileModal = () => setShowProfileModal(true);
  const closeProfileModal = () => setShowProfileModal(false);

  const [email, setEmail] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    } else {
      fetch(`${process.env.REACT_APP_BASE_URL}/api/user/verifyToken`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: localStorage.getItem("token"),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.user) {
            localStorage.removeItem("token");
            navigate("/");
          }
          else {
            setEmail(data.user.email);
          }
        });
    }
  }, [navigate]);


  const handleLogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="usernav">
      <Container>
        <Navbar.Brand>
          <a href="/home">
            <img className="nav-home-logo" src={logo} alt="logo" />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <NavLink to="/home" className={({ isActive }) => isActive ? "active links" : "links"}>
              Dashboard
            </NavLink>
            <NavLink to="/tracks" className={({ isActive }) => isActive ? "active links" : "links"}>
              Learn New
            </NavLink>
            <NavLink to="/enrolled-courses" className={({ isActive }) => isActive ? "active links" : "links"}>
              Enrolled Courses
            </NavLink>
            <NavLink to="/live_class" className={({ isActive }) => isActive ? "active links" : "links"}>
              Book Live Class
            </NavLink>
            <NavLink to="/analytics" className={({ isActive }) => isActive ? "active links" : "links"}>
              Analytics
            </NavLink>
          </Nav>


          <Nav>

            <NavDropdown
              align={{ lg: "start" }}
              title={
                <>
                  <img src={user} className="userIcon" alt="" />
                </>
              }
              className="userProfile"
            >
              <NavDropdown.Item onClick={openProfileModal}>
                <CgProfile /> My Profile
              </NavDropdown.Item>
              <Modal show={showProfileModal} onHide={closeProfileModal} centered>
                <Modal.Header closeButton>
                  <Modal.Title>User Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <UserProfile userInfo={userInfo} />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="danger" onClick={closeProfileModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              <NavDropdown.Item onClick={handleLogOut}>
                <BiLogOut /> Log Out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
