import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Row, Col, Modal, Form } from "react-bootstrap";
import Chart from "chart.js/auto";
import { Line, Doughnut } from "react-chartjs-2";
import { HiLightBulb, HiUserGroup } from "react-icons/hi";
import { IoAnalyticsSharp } from "react-icons/io5";
import { AiTwotoneCode, AiOutlineFileDone } from "react-icons/ai";
import { GiTargetPrize } from "react-icons/gi";
import PreLoader from "../PreLoader/PreLoader";
import UserNavBar from "../UserNavBar/UserNavBar";
import axios from "axios";
import Razorpay from 'react-razorpay';

import Footer from "../Footer/Footer";
import "./LiveClass.css";

export default function LiveClass() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState("");
    const [user, setUser] = useState("");
    const [userInfo, setUserInfo] = useState({});

    const [lineData, setLineData] = useState([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ]);
    const [pieData, setPieData] = useState([0, 0, 0]);

    const [teachers, setTeachers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [rate, setTeacherRate] = useState(0);
    const [teacher, setInTeacher] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [mode, setMode] = useState("offline");
    const [duration, setDuration] = useState(0);
    const [numberOfDays, setNumberOfDays] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);


    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/login");
        } else {
            fetch(`${process.env.REACT_APP_BASE_URL}/api/user/verifyToken`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: localStorage.getItem("token"),
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (!data.user) {
                        localStorage.removeItem("token");
                        navigate("/");
                    } else {
                        setName(data.user.name);
                        setUser(data.user);
                        setUserInfo(data);
                    }
                });
        }
        fetch(`${process.env.REACT_APP_BASE_URL}/api/user/getUserData`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: localStorage.getItem("token"),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                let completed = 0;
                let total = 0;
                let left = 0;
                data.courses.forEach((course) => {
                    total += course.videos.length;
                });
                data.userCourses.map((course) => {
                    completed += course.progress;
                    if (course.completed === true) {
                        const month = new Date(course.date_completed).getMonth();
                        setLineData((prevState) => {
                            const newArray = [...prevState];
                            newArray[month] += 1;
                            return newArray;
                        });
                    }
                });
                left = total - completed;
                setPieData([completed, total, left]);

            });
        setLoading(true);
        setTimeout(() => {
            setLoading(false);

        }, 2500);
    }, [navigate]);

    const handleClick = (e) => {
        e.preventDefault();
        navigate("/tracks");
    };

    const handleClick2 = (e) => {
        e.preventDefault();
        navigate("/analytics");
    };

    const handleClick3 = (e) => {
        e.preventDefault();
        navigate("/practice");
    };

    const handleClick4 = (e) => {
        e.preventDefault();
        navigate("/compete");
    };

    const handleSearch = async (event) => {
        const keyword = event.target.value;
        setSearchTerm(keyword);
        if (keyword.trim() !== '') {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/user/searchteacher/${searchTerm}`);
                const data = await response.json();
                setTeachers(data);
            } catch (error) {
                console.error(error);
            }
        } else {
            setTeachers([]);
        }
    };

    const handleBookNow = (teacher) => {
        setTeacherRate(teacher.rate);
        setInTeacher(teacher);
        setShowModal(true);
    };


    const calculateTotalPayment = () => {
        const hours = parseInt(duration);
        const days = parseInt(numberOfDays);
        const total = (rate * hours) * days;
        setTotalPayment(total);

    };
    const handleDurationChange = (e) => {
        setDuration(e.target.value);
        calculateTotalPayment();

    };

    const handleNumberOfDaysChange = (e) => {
        setNumberOfDays(e.target.value);
        calculateTotalPayment();

    };

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    const handlePayment = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/razorpay/order`, {
                amount: totalPayment * 100, // Amount in paise (1 Rupee = 100 paise)
            });
            const res = await loadScript(
                "https://checkout.razorpay.com/v1/checkout.js"
            );
            if (response.data) {
                const { order_id } = response.data;
                const options = {
                    key_id: `${process.env.RAZORPAY_KEY_ID}`,
                    amount: totalPayment * 100,
                    name: 'Tutflik',
                    description: 'Payment for Teacher Booking',
                    order_id: `${order_id}`,
                    "handler": function (response) {
                        handlePaymentSuccess(response);
                    },
                    prefill: {
                        email: `${user.email}`,
                        contact: `${user.phone}`,
                    },
                };

                const paymentObject = new window.Razorpay(options);
                paymentObject.on('payment.failed', handlePaymentFailure);
                paymentObject.open();
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            console.error('Error creating Razorpay order: ', error);
            alert('Something went wrong');
        }
    };

    const handlePaymentSuccess = (paymentResponse) => {
        // Payment successful
        console.log('Payment successful: ', paymentResponse);
        alert('Payment successful\n Teacher will shortly email you.');
        sendEmailToTeacher();
        setShowModal(false);
    };

    const handlePaymentFailure = (error) => {
        // Payment failed
        console.log('Payment failed: ', error);
        console.error('Payment failed: ', error);
        alert('Payment failed');
    };


    const sendEmailToTeacher = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/sendEmail`, {
                to: teacher.email,
                subject: "Booking Confirmation",
                text: `Dear ${teacher.name},\n\nYour booking has been confirmed for the following details:\nDate: ${date}\nTime: ${time}\nMode: ${mode}\nDuration: ${duration}\nNumber of Days: ${numberOfDays}\nTotal Payment: ₹${totalPayment}\n Student: ${user.name}\n\nThank you for booking with us!\n\nRegards,\nTutflik`,
            });

            if (response.data && response.data.success) {
                alert("Email sent to the teacher");
            } else {
                alert("Something went wrong while sending the email");
            }
        } catch (error) {
            console.log(error);
            alert("Something went wrong while sending the email");
        }
    };

    return (
        <div>
            {/* Navbar component */}
            <UserNavBar userInfo={userInfo} />
            {/* main heading */}
            <div className="user-home container">
                <h2>Hello {name}</h2>
                <h6 className="sub-head">Want to learn live !!</h6>

                {/* Search bar */}
                <h3 class="teacherHeader">Search for Teacher</h3>
                <div class="input-group input-group-lg mb-3">

                    <input type="text" class="form-control" aria-label="Large" aria-describedby="inputGroup-sizing-sm"
                        value={searchTerm}
                        onChange={handleSearch}
                        placeholder="Subject you want to learn" />
                </div>

                {/* Teacher cards */}
                <div className="teacher-cards">
                    {teachers.length === 0 ? (
                        <p>No results found.</p>
                    ) : (
                        teachers.map((teacher) => (
                            <div key={teacher.id} id={teacher.email} className="card mb-2">
                                <div className="card-body d-flex justify-content-between align-items-start">
                                    <div>
                                        <h3 className="card-title">{teacher.name}</h3>
                                        <p className="card-text">Subject: {teacher.subject}</p>
                                        <p className="card-text">Per Hour Rate: ₹{teacher.rate}</p>

                                    </div>
                                    <button onClick={() => handleBookNow(teacher)} className="btn btn-primary">Book Now</button>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Book Now</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formDate">
                            <Form.Label>Date:</Form.Label>
                            <Form.Control
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formTime">
                            <Form.Label>Time:</Form.Label>
                            <Form.Control
                                type="time"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formMode">
                            <Form.Label>Mode:</Form.Label>
                            <Form.Control
                                as="select"
                                value={mode}
                                onChange={(e) => setMode(e.target.value)}
                                required
                            >
                                <option value="offline">Offline</option>
                                <option value="online">Online</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formDuration">
                            <Form.Label>Duration:</Form.Label>
                            <Form.Control
                                type="number"
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                                onKeyUp={calculateTotalPayment}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formNumberOfDays">
                            <Form.Label>Number of Days:</Form.Label>
                            <Form.Control
                                type="number"
                                value={numberOfDays}
                                onChange={(e) => setNumberOfDays(e.target.value)}
                                onKeyUp={calculateTotalPayment}
                                required
                            />
                        </Form.Group>
                        {totalPayment > 0 && (
                            <Form.Group controlId="formTotalPayment">
                                <Form.Label>Total Payment:</Form.Label>
                                <Form.Control type="text" value={`₹${totalPayment}`} readOnly />
                            </Form.Group>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handlePayment} disabled={totalPayment <= 0}>
                        Pay
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* footer */}
            <Footer />
        </div >
    );
};

