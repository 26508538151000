import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Form, Button } from "react-bootstrap";
import "./AdminRegister.css";

export default function Admin() {
    const navigate = useNavigate();

    const [validated, setValidated] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [subject, setSubject] = useState("");
    const [rate, setRate] = useState(0);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            fetch(`${process.env.REACT_APP_BASE_URL}/api/admin/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name,
                    email,
                    subject,
                    rate,
                    password,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        setError(true);
                        setErrorMessage(data.error);
                        setName("");
                        setEmail("");
                        setSubject("");
                        setRate(0);
                        setPassword("");
                        setTimeout(() => {
                            setError(false);
                        }, 3000);
                    } else {
                        localStorage.setItem("adminToken", data.token);
                        navigate("/tutor_home");
                    }
                });
        }
        setValidated(true);
    };

    return (
        <div>
            <div className="container login">
                <div className="login-heading">
                    <h1>Tutflik</h1>
                </div>
                <div
                    className="container"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <Card className="card-login">
                        <Card.Title>Tutor Register</Card.Title>
                        <Card.Body>
                            <Form
                                className="login-form"
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Form.Group className="mb-3" controlId="formName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter valid email.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formSubject">
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Subject"
                                        value={subject}
                                        onChange={(e) => setSubject(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter valid subject.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formRate">
                                    <Form.Label>Per Hour Rate (₹)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Rate"
                                        value={rate}
                                        onChange={(e) => setRate(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter valid rate.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter Password.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Button className="submit-button" type="submit">
                                    LOG IN
                                </Button>
                                <div className="error-message">{error ? errorMessage : ""}</div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}
